import DOMPurify from 'dompurify';
import React from 'react';
import { addCMSUrlToRichText } from 'utils/strapiUtils';

interface Props {
    html: string;
}

const RichTextDisplay = ({ html }: Props) => {
    return (
        <div
            className='rich-text'
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(addCMSUrlToRichText(html)),
            }}
        />
    );
};

export default RichTextDisplay;
