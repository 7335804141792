import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import s from './DropdownCategory.module.scss';

interface Props {
    name: string;
    link: string;
    children: ReactNode;
}

const DropdownCategory = ({ name, children, link }: Props) => {
    return (
        <li className={s.subCategory}>
            <Link to={link}>
                <h4 className={s.title}>{name}</h4>
            </Link>

            <div className={s.divider}></div>
            <ul className={s.categoriesList}>{children}</ul>
        </li>
    );
};

export default DropdownCategory;
