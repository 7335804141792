import React from 'react';
import './Section.scss';

type Props = {
    className?: string;
    children: React.ReactNode;
    title?: string;
};

const Section = ({ className, children, title }: Props) => {
    return (
        <section className={`section${className ? ' ' + className : ''}`}>
            {title && <h2 className='section__title'>{title}</h2>}
            <div className='section__content'>{children}</div>
        </section>
    );
};

export default Section;
