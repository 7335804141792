import React from 'react';
import { Link } from 'react-router-dom';
import s from './DropdownItem.module.scss';

interface Props {
    children: string;
    link: string;
}

const DropdownItem = ({ children, link }: Props) => {
    return (
        <Link to={link}>
            <li className={s.dropdownItem}>{children}</li>
        </Link>
    );
};

export default DropdownItem;
