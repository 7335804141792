import { CircularProgress } from '@mui/material';
import React from 'react';
import s from './Loading.module.scss';

type Props = {};

const Loading = (props: Props) => {
    return (
        <div className={s.container}>
            <CircularProgress size={'200px'} />
        </div>
    );
};

export default Loading;
