import React from 'react';
import s from './Dropdown.module.scss';

interface Props {
    children: React.ReactNode;
    isOpen: boolean;
}

const Dropdown = ({ children, isOpen = false }: Props) => {
    if (!isOpen) return null;

    return (
        <div className={s.dropdown} onClick={(event) => event.preventDefault()}>
            <ul className={s.content}>{children}</ul>
        </div>
    );
};

export default Dropdown;
