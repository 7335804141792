import useScrollToTop from 'hooks/useScrollToTop';
import React from 'react';

type Props = {
    children: React.ReactNode;
};

const ScrollTop = ({ children }: Props) => {
    useScrollToTop();
    return <>{children}</>;
};

export default ScrollTop;
